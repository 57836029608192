import { Injectable } from '@angular/core';
import { catchError, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

/** SERVICIOS */
import { AuthService } from "./auth.service";
/** MODELOS */
import { UsersField } from '../models/users/users-fields';
import { Role } from '../models/users/role';
import { environment } from 'src/environments/environment';
import { TypeUser } from '../models/users/type-user';
import { Force } from '../models/users/force';
import { Otan } from '../models/users/otan';
import { Range } from '../models/users/ranges';

@Injectable()
export class UsersService {

  public roles: Role[];

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  private baseUrlv1: string = environment.UrlApiV1;
  private baseUrlv2: string = environment.UrlApiV2;
  private registerCloudFunctionUrl: string = environment.registerCloudFunction;

  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  getAllForces(): Observable<Force[]> {
    const filters = JSON.stringify({
      where:{isPublic:true}
    })

    let forces$ = this.http
      .get<Force[]>(`${this.baseUrlv1}/Forces?filter=${filters}`,
        { headers: this.getHeaders() })
            .pipe(
          catchError(err => this.authService.errorMessages(err))
      )  
    return forces$;
  }

  getUserTypes(): Observable<TypeUser[]>{
    let filters = '';
    if (environment.principalAppName == 'celic') {
      filters = JSON.stringify({
        where: {and: [
          {isPublic:true},
          {nameTypeUser: {neq: 'Estudiante'}},
        ]}
      });
    } else {
      filters = JSON.stringify({
        where:{isPublic:true}
      });
    }

    return this.http.get<TypeUser[]>(`${this.baseUrlv2}/TypeUsers?filter=${filters}`,
      { headers: this.getHeaders() })
  }

  getOtan(): Observable<Otan[]>{
    return this.http.get<Otan[]>(`${this.baseUrlv2}/Otans`,
      { headers: this.getHeaders() })
  }

  getAllTypeDocuments(): Observable<any> {
    let typeDocuments$ = this.http
      .get(`${this.baseUrlv1}/TypeDocuments`,
        { headers: this.getHeaders() })
            .pipe(
          catchError(err => this.authService.errorMessages(err))
      )  
    return typeDocuments$;
  }

  getAllRangesByForce(id: number | undefined): Observable<Range[]> {

    const filters = JSON.stringify({
      where:{isPublic:true}
    })


    if (id == 0) {

      id = 7;

    }

    let forces$ = this.http
      .get<Range[]>(`${this.baseUrlv1}/Forces/${id}/Ranges?filter=${filters}`,
        { headers: this.getHeaders() })
            .pipe(
          catchError(err => this.authService.errorMessages(err))
      )  
    return forces$;

  }


  public registerUser(data:any){
    return this.http.post(this.registerCloudFunctionUrl, data);
  }

  public preRegisterUser(data:any){
  return  this.http.post( `${ this.baseUrlv2 }/userapps/preRegister`, JSON.stringify( data ), { headers: this.getHeaders() } )
  }
  public validateTokenPreRegister(data:any){
  return  this.http.post( `${ this.baseUrlv2 }/userapps/validateTokenPreRegister`, JSON.stringify( data ), { headers: this.getHeaders() } )
  }



  postNotificationQueue(data): Observable<any>{
    return this.http
      .post(`${this.baseUrlv2}/NotificationQueues`, data, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )
  }

  createStudent(person: UsersField): Observable<UsersField> {
    person.CedocEmail = getCleanedString(`${person.Name1}${person.LastName1}${person.LastName2}`);
    person.id = undefined;
    console.debug(person);

    let responsePost = this
      .http
      .post(`${this.baseUrlv1}/userapps/student`,
        JSON.stringify(person),
        { headers: this.getHeaders() })
      .pipe(
          catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;
  }

  createInstructor(person: UsersField): Observable<UsersField> {

    person.CedocEmail = getCleanedString(`${person.Name1}${person.LastName1}${person.LastName2}`);
    person.id = undefined;
    console.debug(person);

    let responsePost = this
      .http
      .post(`${this.baseUrlv1}/userapps/instructor`,
        JSON.stringify(person),
        { headers: this.getHeaders() })
      .pipe(
          catchError(err => this.authService.errorMessages(err))
      )
    return responsePost;

  }

  /**
   * Inserts a new attendant
   * @param person 
   * @returns 
   */
  public createAttendant( person: UsersField ): Observable<UsersField> {
    person.CedocEmail = getCleanedString( `${ person.Name1 }${ person.LastName1 }${ person.LastName2 }` );
    person.id = undefined;
    console.debug( person );

    let responsePost = this
      .http
      .post( `${ this.baseUrlv1 }/userapps/attendant`,
        JSON.stringify( person ),
        { headers: this.getHeaders() } )
      .pipe(
        catchError( err => this.authService.errorMessages( err ) )
      )
    return responsePost;

  }



  temporalLogin(email: string, password: string): Observable<any> {

    let url = this.baseUrlv1 + "/Userapps/login";
    return this.http.post(url, { email: email, password: password },
      { headers: this.getHeaders() })
      .pipe(
        take(1) /** TOD: review error managment */
        // catchError(err => {
        //     console.log('Handling error locally and rethrowing it...', err);
        //     return throwError(err);
        // })
    )

  }
  postContrasena(Correo: string, userDocument: string): Observable<any> {
    let url = this.baseUrlv2 + "/Userapps/resetPassword";
    return this.http.post(url, { Document: userDocument, email: Correo })
  }

}

function getCleanedString(cadena) {
  // Definimos los caracteres que queremos eliminar
  let specialChars = "!@#$^&%*()+=-[]{}|:<>?,.";

  // Los eliminamos todos
  for (const char of specialChars) {
    cadena = cadena.replace(new RegExp("\\" + char, 'gi'), '').replace(/\\/g, '').replace(/\//g, '');
  }

  // Lo queremos devolver limpio en minusculas
  cadena = cadena.toLowerCase();

  // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
  cadena = cadena.replace(/ /g, "");
  cadena = cadena.replace(/undefined/g, "");
  cadena = cadena.replace(/null/g, "");

  // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
  cadena = cadena.replace(/á/gi, "a");
  cadena = cadena.replace(/é/gi, "e");
  cadena = cadena.replace(/í/gi, "i");
  cadena = cadena.replace(/ó/gi, "o");
  cadena = cadena.replace(/ú/gi, "u");
  cadena = cadena.replace(/ü/gi, "u");
  cadena = cadena.replace(/ñ/gi, "n");

  return cadena;

}