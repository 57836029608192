<div class="register">
  <div class="register-header">
    <button class="btn btn--close-btn" (click)="showHideRegister()">
      <em class="icon-i-close"></em>
    </button>

    <span class="register-header__tittle" *ngIf="!userCreated">Bienvenido a {{SCHOOL_NAME}} 360</span>
    <span class="register-header__tittle" *ngIf="userCreated">Usuario creado!</span>
  </div>
  <ng-container *ngIf="newUserState.currentState == 'preRegister'">
    <div class="registerDev" *ngIf="!IS_PRODUCTION">Se encuentra en un ambiente de prueba, por favor ingrese el correo test&#64;test.com para saltar este paso.</div>

    <span class="register-form__title-span">Antes de inciar el proceso de registro necesitamos validar su correo, por
      favor indique su correo personal.</span>

    <form class="register-form" [formGroup]="preregisterForm" (submit)="preRegister()">
      <div class="register-form__col">
        <div class="register-form__col-2">
            <div class="register-form__control">
              <input type="text" formControlName="email"
                [ngClass]="{'has-value':checkInputValue(preregisterForm.get('email')?.value)}">
              <label>Correo electrónico*</label>
            </div>
        </div>
        <div class="register-form__col-2">
          <strong (click)="newUserState.currentState='preRegisterToken'" class="textoOlvidoCredenciales">Tengo código de verificación  </strong>
        </div>
      </div>


      <div class="register-form__actions">
        <div class="register-form__captcha">
          <re-captcha formControlName="captcha" siteKey="6LdPSnkUAAAAANUJGmUtAjHNtF8ctRTkmlA-xdqc"></re-captcha>
          <script src="https://www.google.com/recaptcha/api.js?onload=CaptchaCallback&render=explicit" async defer
            integrity="sha384-oqVuAfXRKap7fdgcCY5uykM6+R9GqQ8K/uxy9rx7HNQlGYl1kPzQho1wx4JwY8wC"></script>
        </div>



        <button type="submit" class="btn btn--register" [ngClass]="loading  ? 'disabled' : ''">{{loading ? 'Creando...'
          : 'Validar'}}</button>
        <ul class="error-list">
          <li class="error-list__item error-list__item--center" *ngIf="serverErrors">{{serverErrors}}</li>
          <li class="error-list__item error-list__item--center" *ngIf="!registerForm.valid && checkErrors">Hay campos
            sin completar.</li>
        </ul>

      </div>
    </form>
  </ng-container>


  <ng-container *ngIf="newUserState.currentState == 'preRegisterToken' ">
    <span class="register-form__title-span">Validar email para registro
    </span>

    <form class="register-form" [formGroup]="validateTokenForm" (submit)="validateToken()">
      <div class="register-form__col">
        <div class="register-form__col-2">
            <div class="register-form__control">
              <input  type="text" formControlName="email"
                [ngClass]="{'has-value':checkInputValue(validateTokenForm.get('email')?.value)}" >
              <label>Correo electrónico*</label>
            </div>
        </div>
        <div class="register-form__col-2">
            <div class="register-form__control">
              <input type="text" formControlName="token"
                [ngClass]="{'has-value':checkInputValue(validateTokenForm.get('email')?.value)}">
              <label>Código de verificación*
              </label>
            </div>
        </div>
        
      </div>


      <div class="register-form__actions">
        <div class="register-form__captcha">
          <re-captcha formControlName="captcha" siteKey="6LdPSnkUAAAAANUJGmUtAjHNtF8ctRTkmlA-xdqc"></re-captcha>
          <script src="https://www.google.com/recaptcha/api.js?onload=CaptchaCallback&render=explicit" async defer
            integrity="sha384-oqVuAfXRKap7fdgcCY5uykM6+R9GqQ8K/uxy9rx7HNQlGYl1kPzQho1wx4JwY8wC"></script>
        </div>



        <button type="submit" class="btn btn--register" [ngClass]="loading  ? 'disabled' : ''">{{loading ? 'Creando...'
          : 'Validar'}}</button>
        <ul class="error-list">
          <li class="error-list__item error-list__item--center" *ngIf="serverErrors">{{serverErrors}}</li>
          <li class="error-list__item error-list__item--center" *ngIf="!registerForm.valid && checkErrors">Hay campos
            sin completar.</li>
        </ul>

      </div>
    </form>
  </ng-container>




  <ng-container *ngIf="newUserState.currentState == 'preRegisterValid'"> 

    <span class="register-form__title-span">Todos los campos con asterisco * son obligatorios</span>

    <form class="register-form" [formGroup]="registerForm" (submit)="register()">
      <div class="register-form__col" [hidden]="pageIndex > 0">
        <div class="register-form__col-2">

          <div formGroupName="basicInfo">
            <div class="register-form__control">
              <input type="text" formControlName="firstName"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('basicInfo.firstName')?.value)}">
              <label>Primer nombre*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('basicInfo.firstName')?.hasError('required') && (registerForm.get('basicInfo.firstName')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>

          <div formGroupName="basicInfo">
            <div class="register-form__control">
              <input type="text" formControlName="middleName"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('basicInfo.middleName')?.value)}">
              <label>Segundo nombre</label>
            </div>
          </div>

          <div formGroupName="basicInfo">
            <div class="register-form__control">
              <input type="text" formControlName="lastName"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('basicInfo.lastName')?.value)}">
              <label>Primer apellido*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('basicInfo.lastName')?.hasError('required') && (registerForm.get('basicInfo.lastName')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>

          <div formGroupName="basicInfo">
            <div class="register-form__control">
              <input type="text" formControlName="secondLastName"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('basicInfo.secondLastName')?.value)}">
              <label>Segundo apellido</label>
            </div>
          </div>
        </div>

        <div class="register-form__col-2">
          <div formGroupName="documentInfo">
            <div class="register-form__control">
              <select formControlName="documentTypeId"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('documentInfo.documentTypeId')?.value)}">
                <option *ngFor="let typeDocument of typeDocuments" [ngValue]="typeDocument.id">{{
                  typeDocument.NameTypeDocument }}</option>
              </select>

              <label>Tipo de documento*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('documentInfo.documentTypeId')?.hasError('required') && (registerForm.get('documentInfo.documentTypeId')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>

          <div formGroupName="documentInfo">
            <div class="register-form__control">
              <input type="text" formControlName="document" 
                (change)="clearSpaces($event, 'documentInfo.document')"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('documentInfo.document')?.value)}">
              <label>Número de documento*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('documentInfo.document')?.hasError('required') && (registerForm.get('documentInfo.document')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>
        </div>

        <div class="register-form__col-2">
          <div formGroupName="contactInfo">
            <div class="register-form__control">
              <input type="text" formControlName="cellphone"
                (change)="clearSpaces($event, 'contactInfo.cellphone')"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('contactInfo.cellphone')?.value)}">
              <label>Número de celular*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('contactInfo.cellphone')?.hasError('required') && (registerForm.get('contactInfo.cellphone')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>

          <div formGroupName="basicInfo">
            <div class="register-form__control">
         
              <input type="text" formControlName="email" 
                [ngClass]="{'has-value':checkInputValue(registerForm.get('basicInfo.email')?.value)}">
              <label>Correo electrónico*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('basicInfo.email')?.hasError('required') && (registerForm.get('basicInfo.email')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
              <li class="error-list__item"
                *ngIf="registerForm.get('basicInfo.email')?.hasError('invalidEmailDomain') && (registerForm.get('basicInfo.email')?.touched || checkErrors)">
                Correo inválido.</li>
            </ul>
          </div>
        </div>

        <div class="register-form__col-2">
          <div>
            <div class="register-form__control">
              <select (change)="onCallDepartments($event)" formControlName="department"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('department')?.value)}">
                <option *ngFor="let department of departments" [value]="department">{{ department }}</option>
              </select>
              <label>Departamento*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('department')?.invalid && (registerForm.get('department')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>

          <div formGroupName="contactInfo" [ngClass]="cities.length === 0 ? 'disabled':''">
            <div class="register-form__control">
              <select formControlName="city"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('contactInfo.city')?.value)}">
                <option *ngFor="let city of cities" [ngValue]="city">{{ city }}</option>
              </select>
              <label>Ciudad*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('contactInfo.city')?.hasError('required') && (registerForm.get('contactInfo.city')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="register-form__col" [hidden]="pageIndex < 1">
        <div class="register-form__col-2">
          <ng-container formGroupName="basicInfo">
            <div>
              <div class="register-form__control">
                <select formControlName="typeUserId" (change)="onCallMilitary()"
                  [ngClass]="{'has-value':checkInputValue(registerForm.get('basicInfo.typeUserId')?.value)}">
                  <option [ngValue]="item.id" *ngFor="let item of typeUser">{{item.nameTypeUser}}</option>
                </select>
                <label>Personal*</label>
              </div>
  
              <ul class="error-list">
                <li class="error-list__item"
                  *ngIf="registerForm.get('basicInfo.typeUserId')?.hasError('required') && (registerForm.get('basicInfo.typeUserId')?.touched || checkErrors)">
                  Este campo es obligatorio.</li>
              </ul>
            </div>
          </ng-container>

          <div formGroupName="militaryInfo" *ngIf="registerForm.get('isMilitary')?.value === true">
            <div class="register-form__control">
              <input type="text" formControlName="militaryDocument"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('militaryInfo.militaryDocument')?.value)}">
              <label>Cédula Militar*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('militaryInfo.militaryDocument')?.hasError('required') && (registerForm.get('militaryInfo.militaryDocument')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>
        </div>

        <div>
          <div class="register-form__control">
            <select formControlName="accountType" (change)="onCallAccountType()"
              [ngClass]="{'has-value':checkInputValue(registerForm.get('accountType')?.value)}">
              <option [value]="'student'" *ngIf="principalAppName=='cedoc'">Aspirante / Estudiante</option>
              <option [value]="'instructor'">Profesor</option>
              <option [value]="'graduate'">Egresado</option>
              <option [value]="'attendant'" *ngIf="principalAppName=='celic'">Acudiente</option>
            </select>
            <label>Tipo de usuario a registrar*</label>
          </div>

          <ul class="error-list">
            <li class="error-list__item"
              *ngIf="registerForm.get('accountType')?.hasError('required') && (registerForm.get('accountType')?.touched || checkErrors)">
              Este campo es obligatorio.</li>
          </ul>
        </div>

        <div class="register-form__col-2">
          <div formGroupName="militaryInfo" *ngIf="registerForm.get('isMilitary')?.value === true">
            <div class="register-form__control">
              <select formControlName="forceId" (change)="onCallForce()"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('militaryInfo.forceId')?.value)}">
                <option *ngFor="let force of forces" [ngValue]="force.id">{{ force.NameForce }}</option>
              </select>
              <label>Fuerza*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('militaryInfo.forceId')?.hasError('required') && (registerForm.get('militaryInfo.forceId')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>

          <div formGroupName="militaryInfo" *ngIf="registerForm.get('isMilitary')?.value === true"
            [ngClass]="ranges.length === 0 ? 'disabled':''">
            <div class="register-form__control">
              <select formControlName="realRangeID"
                [ngClass]="{'has-value':checkInputValue(registerForm.get('militaryInfo.realRangeID')?.value)}">
                <option *ngFor="let range of ranges" [ngValue]="range.id">{{ range.NameRange }}</option>
              </select>
              <label>Grado*</label>
            </div>

            <ul class="error-list">
              <li class="error-list__item"
                *ngIf="registerForm.get('militaryInfo.realRangeID')?.hasError('required') && (registerForm.get('militaryInfo.realRangeID')?.touched || checkErrors)">
                Este campo es obligatorio.</li>
            </ul>
          </div>
        </div>

        <div formGroupName="courseInfo"
          *ngIf="registerForm.get('accountType')?.value && registerForm.get('accountType')?.value != 'attendant'">
          <div class="register-form__control">
            <select formControlName="schoolId" (change)="onCallSchool()"
              [ngClass]="{'has-value':checkInputValue(registerForm.get('courseInfo.schoolId')?.value)}">
              <option *ngFor="let school of schools" [ngValue]="school.id">{{ school.NameTSchool }}</option>
            </select>
            <label>{{SCHOOL_NAME === 'CELIC' ? 'Liceos' : 'Escuela'}}*</label>
          </div>

          <ul class="error-list">
            <li class="error-list__item"
              *ngIf="registerForm.get('courseInfo.schoolId')?.hasError('required') && (registerForm.get('courseInfo.schoolId')?.touched || checkErrors)">
              Este campo es obligatorio.</li>
          </ul>
        </div>

        <div formGroupName="courseInfo"
          *ngIf="registerForm.get('accountType')?.value && registerForm.get('accountType')?.value != 'instructor' && registerForm.get('accountType')?.value != 'attendant'"
          [ngClass]="courses.length === 0 ? 'disabled':''">
          <div class="register-form__control">
            <select formControlName="courseOfferId"
              [ngClass]="{'has-value':checkInputValue(registerForm.get('courseInfo.courseOfferId')?.value)}">
              <option *ngFor="let course of courses" [ngValue]="course.id">{{ course.NameCourseOfer }}</option>
            </select>
            <label>{{ SCHOOL_NAME === 'CELIC' ? 'Grado al que aspira' : 'Curso al que fue convocado' }}*</label>
          </div>

          <ul class="error-list">
            <li class="error-list__item"
              *ngIf="registerForm.get('courseInfo.courseOfferId')?.hasError('required') && (registerForm.get('courseInfo.courseOfferId')?.touched || checkErrors)">
              Este campo es obligatorio.</li>
          </ul>
        </div>
      </div>

      <div class="register-form__actions">
        <div class="register-form__navigation">
          <button class="btn btn--navigation__left" type="button" (click)="previousPage()"
            [ngClass]="pageIndex === 0 ? 'disabled': ''">
            <em class="icon-i-angle-right"></em>
          </button>
          <button class="btn btn--navigation__right" [ngClass]="pageIndex === 1 ? 'disabled': ''" type="button"
            (click)="nextPage()">
            <em class="icon-i-angle-right"></em>
          </button>
        </div>

        <div class="register-form__captcha" [hidden]="pageIndex < 1">
          <re-captcha formControlName="captcha" siteKey="6LdPSnkUAAAAANUJGmUtAjHNtF8ctRTkmlA-xdqc"></re-captcha>
          <script src="https://www.google.com/recaptcha/api.js?onload=CaptchaCallback&render=explicit" async defer
            integrity="sha384-oqVuAfXRKap7fdgcCY5uykM6+R9GqQ8K/uxy9rx7HNQlGYl1kPzQho1wx4JwY8wC"></script>
        </div>

        <div class="conditions-check">
          <input type="checkbox" formControlName="termsCond" value="true" />
          <a [routerLink]="['/privacy-policy']" class="text-acept" target="_blank" rel='noopener'> Acepto los términos y
            condiciones</a>
        </div>

        <button type="submit" class="btn btn--register" [ngClass]="loading  ? 'disabled' : ''"
          [hidden]="pageIndex < 1">{{loading ? 'Creando...' : 'Crear'}}</button>
        <ul class="error-list">
          <li class="error-list__item error-list__item--center" *ngIf="serverErrors">{{serverErrors}}</li>
          <li class="error-list__item error-list__item--center" *ngIf="!registerForm.valid && checkErrors">Hay campos
            sin completar.</li>
        </ul>

        <span (click)="showHideLoginRegister()" class="login-link">Si ya tienes una cuenta, ingrese aquí</span>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="newUserState.currentState == 'registered'"   >
    <div class="register-confirm">
      <div class="register-confirm__data">


        <span class="confirm-data__title">{{ showAttendantData ? 'CORREO:' : 'CORREO TEMPORAL:' }} <span
            class="confirm-data__text">{{ showAttendantData ? attendantEmail : tempUser }}</span></span>
        <span class="confirm-data__title">CONTRASEÑA: <span class="confirm-data__text">Su teléfono celular</span></span>
      </div>

      <div class="register-confirm__message">
        <span>{{ showAttendantData ? 'REGISTRO EXITOSO' : 'ESTE ES UN USUARIO TEMPORAL, UNA VEZ VALIDADO SE LE BRINDARÁ
          UN CORREO OFICIAL.' }}</span>
      </div>

      <span class="register-confirm__message-info">
        {{ showAttendantData ? 'Este usuario' : 'El usuario temporal' }} es el que le permitirá tener acceso a la
        plataforma {{SCHOOL_NAME}}{{ showAttendantData ? '.' : ' 360 para verificar su estado de inscripción.' }}
      </span>

      <div class="register-confirm__actions">
        <button type="submit" class="btn btn--register" (click)="showHideLoginRegister()">
          Ingresar
        </button>
      </div>
    </div>
  </ng-container>
</div>