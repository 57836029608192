import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { NewDataComponent } from './components/new-data/new-data.component';
import { LandingComponent } from './components/landing/landing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { UsersService } from './services/users.service';
import { CoursesService } from './services/courses.service';
import { ArticleService } from './services/article.service';
import { LibraryComponent } from './components/library/library.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { LoginCardComponent } from './components/shared/login-card/login-card.component';
import { RegisterCardComponent } from './components/shared/register-card/register-card.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RegisterService } from './services/register.service';
import { QrValidationComponent } from './components/qr-validation/qr-validation.component';
import { NewsViewComponent } from './components/news-view/news-view.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { TutorialContainerComponent } from './components/shared/tutorial-container/tutorial-container.component';

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    LandingComponent,
    LibraryComponent,
    NewDataComponent,
    FooterComponent,
    NavbarComponent,
    LoginCardComponent,
    RegisterCardComponent,
    QrValidationComponent,
    NewsViewComponent,
    TutorialComponent,
    TutorialContainerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    CarouselModule,
    ReactiveFormsModule
  ],
  providers: [
    UsersService,
    CoursesService,
    ArticleService,
    RegisterService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
