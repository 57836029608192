import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Course } from 'src/app/models/courses/course';
import { Module } from 'src/app/models/courses/module';
import { School } from 'src/app/models/school';
import { Force } from 'src/app/models/users/force';
import { Range } from 'src/app/models/users/ranges';
import { TypeDocument } from 'src/app/models/users/typedocument';
import { CoursesService } from 'src/app/services/courses.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { CourseOffersService } from './resources/courseOffers';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { TypeUser } from 'src/app/models/users/type-user';

@Component({
  selector: 'landing-register-card',
  templateUrl: './register-card.component.html',
  styleUrls: ['./register-card.component.css']
})
export class RegisterCardComponent implements OnInit {
  @Output('closeBox') closeBox: EventEmitter<any> = new EventEmitter();
  @Output('changeBox') changeBox: EventEmitter<any> = new EventEmitter();

  public IS_PRODUCTION: boolean = environment.production;
  public registerForm: FormGroup = this._fb.group({
    schoolName: ['', Validators.required],
    captcha: ['', Validators.required],
    termsCond: ['', Validators.requiredTrue],
    department: ['', Validators.required],

    isTest: [true, Validators.required],
    isMilitary: ['', Validators.required],
    accountType: ['', Validators.required],

    basicInfo: this._fb.group({
      typeUserId: [null, Validators.required],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      secondLastName: [''],
      email: [{ value: '', disabled: true }, [Validators.required, this.emailValidator], { disabled: false }],
    }),

    documentInfo: this._fb.group({
      documentTypeId: ['', Validators.required],
      document: ['', Validators.required],
    }),

    contactInfo: this._fb.group({
      cellphone: ['', Validators.required],
      city: ['', Validators.required],
      email: [''],
    }),

    courseInfo: this._fb.group({
      courseOfferId: ['', Validators.required],
      schoolId: ['', Validators.required],
      jobOfferId: [''], //Required if instructor
    }),

    //Required if military
    militaryInfo: this._fb.group({
      militaryDocument: [''],
      forceId: [''],
      realRangeID: ['']
    }),
  });
  public preregisterForm: FormGroup = this._fb.group({
    email: ['', Validators.required],
    captcha: ['', Validators.required],
  });
  public validateTokenForm: FormGroup = this._fb.group({
    email: ['', Validators.required],
    token: ['', Validators.required],
    captcha: ['', Validators.required],
  });

  public principalAppName = environment.principalAppName;

  public checkErrors: boolean = false;
  public loading: boolean = false;
  public typeDocuments: TypeDocument[] = [];
  public departments: any[] = [];
  public cities: any[] = [];
  public ranges: Range[] = [];
  public pageIndex: number = 0;
  public userCreated: boolean = false;
  public newUserState = { currentState: "preRegister" };
  public showAttendantData: boolean = false;
  public attendantEmail: string = '';
  public modules: Module[] = [];
  public courses: Course[] = [];
  public schools: School[] = [];
  public forces: Force[] = [];
  public typeUser: TypeUser[] = [];
  public tempUser: string | undefined = 'Nombre1Apellido1Apellido2@cedoc.edu.co';
  public serverErrors: string = '';
  public SCHOOL_NAME: string = environment.SCHOOL_NAME;

  constructor(
    private _coursesService: CoursesService,
    private _usersService: UsersService,
    private _fb: FormBuilder,
    private _sweetalertService: SweetalertService,
    private _courseOffers: CourseOffersService
  ) { }


  ngOnInit(): void {
    this.registerForm.patchValue({
      schoolName: this.SCHOOL_NAME,
      isTest: !environment.production
    })

    //Get main data
    forkJoin({
      typeDocuments: this._usersService.getAllTypeDocuments(),
      departments: this._coursesService.getDepartments(),
      forces: this._usersService.getAllForces(),
      schools: this._coursesService.getAllSchools(),
      typeUser: this._usersService.getUserTypes(),
    }).subscribe({
      next: ({ typeDocuments, departments, forces, schools, typeUser }) => {
        this.typeDocuments = typeDocuments;
        this.departments = departments;
        this.forces = forces.sort((a,b) => a.NameForce!.localeCompare(b.NameForce!.toString()));
        this.typeUser = typeUser;
        this.schools = schools;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  public preRegister() {
    this.newUserState.currentState = "preRegister"
    this.tempUser = '';
    this.serverErrors = '';
    this.checkErrors = true;

    const erroredFields = [];
    this.findErroredFields(this.preregisterForm, erroredFields);
    console.log("this.preregisterForm.value",this.preregisterForm.value)

  
    if (this.preregisterForm.value.email == "test@test.com" && this.preregisterForm.valid && this.IS_PRODUCTION==false) {
      this.newUserState.currentState = "preRegisterValid"
      this.registerForm.patchValue({ basicInfo: { email: this.preregisterForm.value.email } })
      return
    }
    if (!this.preregisterForm.valid)
      return;
    this._sweetalertService.swalLoading('Enviando correo de validación', 'Esta ventana se cerrará automáticamente, Espere por favor...');

    this.loading = true;
    this._usersService.preRegisterUser(this.preregisterForm.value).subscribe({
      next: (resp: any) => {

        const { message, status, data } = resp;

        this.loading = false;
        this.checkErrors = false;
        this._sweetalertService.swalSuccess("Correo Enviado", resp.message, () => { })
        this.newUserState.currentState = "preRegisterToken"



      }, error: (err) => {
        this._sweetalertService.swalError('Error', `${err.error.error.message}`, () => { });
        this.newUserState.currentState = "preRegister"
        this.loading = false;
        this.userCreated = false;


      }
    })
  }

  public validateToken() {


    this.newUserState.currentState = "preRegister"
    this.tempUser = '';
    this.serverErrors = '';
    this.checkErrors = true;

    const erroredFields = [];
    this.findErroredFields(this.validateTokenForm, erroredFields);

    if (!this.validateTokenForm.valid)
      return;
    this._sweetalertService.swalLoading('Validando Token', 'Esta ventana se cerrará automáticamente, Espere por favor...');

    this.loading = true;
    this._usersService.validateTokenPreRegister(this.validateTokenForm.value).subscribe({
      next: (resp: any) => {
        this.loading = false;

        this.checkErrors = false;
        this._sweetalertService.swalSuccess("Token Validado", resp.message, () => { })
        this.newUserState.currentState = "preRegisterValid"
        this.registerForm.patchValue({ basicInfo: { email: this.validateTokenForm.value.email } })
      }, error: (err) => {
        this._sweetalertService.swalError('Error', `${err.error.error.message}`, () => { });
        this.newUserState.currentState = "preRegisterToken"
        this.loading = false;
        this.userCreated = false;
      }
    })
  }

  /**
   * Register a new user
   * @returns
   */
  public register() {
    this.userCreated = false;
    this.tempUser = '';
    this.serverErrors = '';
    this.checkErrors = true;

    const erroredFields = [];
    this.findErroredFields(this.registerForm, erroredFields);

    if (!this.registerForm.valid)
      return;

    this.showAttendantData = (this.registerForm.get('accountType')?.value === 'attendant');
    this.attendantEmail = this.registerForm.get('basicInfo.email')?.value;

    //Set the phone and personal email
    this.registerForm.patchValue({
      contactInfo: {
        cellphone: this.registerForm.get('contactInfo.cellphone')?.value.toString(),
        email: this.registerForm.get("basicInfo.email")!.value
      }
    });
    this.modalDefaultLoading('Registrando', 'Esta ventana se cerrara automaticamente, Espere por favor...');
    this.loading = true;
    this._usersService.registerUser(this.registerForm.getRawValue()).subscribe({
      next: (resp: any) => {
        const { message, status, data } = resp;

        this.loading = false;
        this.modalClose();
        this.checkErrors = false;

        if(status !== 'OK' ){
          this.serverErrors = message;
          return;
        }

        this.tempUser = data.CedocEmail;
        this.userCreated = true;

        this.newUserState.currentState = "registered"

        this.postNotificationQueue();
      }, error: (err) => {
        this.modalErrorMsg('Error', 'Inténtelo de nuevo. Si el error persiste, póngase en contacto con el soporte.');
        this.loading = false;
        this.userCreated = false;
        this.serverErrors = err.error.message || "Error interno del servidor";
        console.log(err);
      }
    })
  }

  /**
   * When selecting a force
   */
  postNotificationQueue() {
    let data = {
      emailsTo: `["${this.registerForm.get('contactInfo.email')!.value}"]`,
      namesTO: `["Registro en la plataforma ${this.principalAppName.toUpperCase()}"]`,
      msg: `[{"message":"Su nombre de usuario en la plataforma es ${this.showAttendantData ? this.attendantEmail : this.tempUser}, y su contraseña es el número de celular con el cual se registró."}]`,
      timeToSend: new Date().toISOString(),
      isSend: false,
      isSingleMessage: true,
      typeNotification: 'email',
      senderName: null,
      senderEmail: null,
      atachment: null,
      templateFile: 'template.html'
    }

    this._usersService.postNotificationQueue(data).subscribe({
      next: (resp) => {
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  /**
   * When selecting a force
   */
  public onCallForce() {
    const value: number = this.registerForm.get('militaryInfo.forceId')?.value;
    this.ranges = [];

    //Reset range
    this.ranges = [];

    this._usersService.getAllRangesByForce(value).subscribe({
      next: (resp) => {
        this.ranges = resp.sort((a,b) => a.NameRange!.localeCompare(b.NameRange!));
      }, error: (err) => {
        console.log(err);
      }
    });
  }


  /**
   * Selecting military
   */
  public onCallMilitary() {
    this.registerForm.patchValue({
      isMilitary: this.typeUser.find(e => e.id == this.registerForm.get('basicInfo.typeUserId')!.value)!.isMilitar,
    });

    const value: boolean = this.registerForm.get('isMilitary')?.value;
    this.registerForm.get('militaryInfo')?.patchValue({ militaryDocument: '', forceId: '' });
    this.ranges = [];

    //Set required fields
    if (value === true) {
      this.registerForm.get('militaryInfo.militaryDocument')?.setValidators(Validators.required);
      this.registerForm.get('militaryInfo.forceId')?.setValidators(Validators.required);
    } else {
      this.registerForm.get('militaryInfo.militaryDocument')?.clearValidators();
      this.registerForm.get('militaryInfo.forceId')?.clearValidators();
    }

    this.registerForm.get('militaryInfo.militaryDocument')?.updateValueAndValidity();
    this.registerForm.get('militaryInfo.forceId')?.updateValueAndValidity();
  }

  /**
   * Set courses and school
   *
   * @param event
   */
  public onCallAccountType() {
    const value = this.registerForm.get('accountType')?.value

    this.registerForm.patchValue({ courseInfo: { courseOfferId: '', schoolId: '', jobOfferId: '' } });
    this.courses = [];

    //Assign school id according to school name
    const mainSchoolId = this.SCHOOL_NAME === 'CELIC' ? 1 : 25;

    this.registerForm.patchValue({
      courseInfo: {
        //If attendant, can't select school, instead set the mainSchoolId
        schoolId: value === 'attendant' ? mainSchoolId : this.registerForm.get('courseInfo.schoolId')?.value,

        //If attendant, the courseOfferId must be 3636 celic / cedoc
        courseOfferId: value === 'attendant' ? 3636 : this.registerForm.get('courseInfo.schoolId')?.value,
      },
    })

    if (value === 'instructor')
      this.registerForm.get('courseInfo.jobOfferId')?.setValidators(Validators.required);
    else
      this.registerForm.get('courseInfo.jobOfferId')?.clearValidators();

    this.registerForm.get('courseInfo.jobOfferId')?.updateValueAndValidity();
  }


  /**
   * Selecting school
   */
  public onCallSchool() {
    const value: number = this.registerForm.get('courseInfo.schoolId')?.value;
    this.courses = [];

    forkJoin({
      courses: this._coursesService.getAllCoursesOfersBySchool(value),
      areas: this._coursesService.getAllAcademicAreasBySchool(value),
    }).subscribe({
      next: ({ courses, areas }) => {
        this.courses = courses;

        if (this.registerForm.get('accountType')?.value !== 'instructor')
          return;

        //If accountType is instructor, set the default courseOffer and academicArea of the file courseOffers.ts
        const schoolName = this.SCHOOL_NAME.toLowerCase();
        const schoolId = this.registerForm.get('courseInfo.schoolId')?.value;
        const courseOffer = this._courseOffers.getCourseValue(schoolName, schoolId);

        //The academic area will always be first in the array
        const academicArea: number = areas[0].id;

        this.registerForm.patchValue({ courseInfo: { jobOfferId: academicArea, courseOfferId: courseOffer } });
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  /**
   * Navigate to the next page
   */
  public nextPage() {
    if (this.pageIndex < 1) {
      this.pageIndex++;
    }
  }

  /**
   * Navigate to the previous page
   */
  public previousPage() {
    if (this.pageIndex > 0) {
      this.pageIndex--;
    }
  }

  /**
   * Gets cities by department
   * 
   * @param event 
   */
  public onCallDepartments(event: any) {
    this.cities = [];
    this.registerForm.get('contactInfo')?.patchValue({ city: '' });

    this._coursesService.getCities(event.target.value).subscribe({
      next: (resp: any) => {
        this.cities = resp;
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  /**
   * Custom e-mail validator for formControl
   * 
   * @param control 
   * @returns 
   */
  private emailValidator(control: AbstractControl): { [key: string]: any } | null {
    const email: string = control.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email && !emailRegex.test(email))
      return { 'invalidEmailDomain': true };

    return null;
  }

  /**
   * FOR DEBUGGING
   * Find errors in the form
   * 
   * @param formGroup 
   * @param erroredFields 
   */
  private findErroredFields(formGroup: FormGroup, erroredFields: any[]) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.findErroredFields(control, erroredFields);
      } else if (control?.errors !== null) {
        erroredFields.push({ field: key, errors: control?.errors });
      }
    });
  }

  public checkInputValue(field: any): boolean {
    if (field === null || field === undefined)
      return false;

    const value = field.toString().trim();
    return value !== '';
  }

  public clearSpaces(event:Event, controlName:string){
    const input = event.target as HTMLInputElement;
    let value = input.value.trim();
    value = value.replace(/\s+/g, '');
    input.value = value;
    this.registerForm.get(controlName)?.setValue(value);
  }


  showHideRegister() {
    this.closeBox.emit();
  }

  showHideLoginRegister() {
    this.changeBox.emit();
  }

  modalDefaultLoading(titulo: string, mensaje: string): void {
    this._sweetalertService.swalLoading(titulo, mensaje);
  }

  modalErrorMsg(titulo: string, mensaje: string): void {
    this._sweetalertService.swalErrorMsg(titulo, mensaje);
  }

  modalClose(): void {
    this._sweetalertService.swalClose();
  }
}