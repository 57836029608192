import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'landing-cedoc-support',
  templateUrl: './cedoc-support.component.html',
  styleUrls: ['./cedoc-support.component.css']
})
export class CedocSupportComponent implements OnInit {

  public SCHOOL_NAME:string = environment.SCHOOL_NAME;

  constructor() { }

  ngOnInit(): void {
  }

}
