import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'landing-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  public categories = [
    {
      label: "Registro",
      value: "register",
    }
  ]

  public tutorials: any[] = [
    {
      id: 1,
      title: "Registro Acudiente",
      category: "register",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("registro-acudiente", "jpg"),
      link: "https://drive.google.com/file/d/1xerBcg4BdSwnVawQ9q_St2lq7T3Up1UB/view?usp=drive_link",
    },
    {
      id: 2,
      title: "Registro Docente",
      category: "register",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("registro-docente", "jpg"),
      link: "https://drive.google.com/file/d/16vmNq5FXMBbq8XjnsHL5RBYhdWErHf-v/view?usp=drive_link",
    },
    {
      id: 3,
      title: "Registro Estudiante",
      category: "register",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("registro-estudiante", "jpg"),
      link: "https://drive.google.com/file/d/1jaeI3FvO3n4UrW21OoC6pr8wizimNt1r/view?usp=drive_link",
    },
    {
      id: 4,
      title: "Inscripción Estudiantes",
      category: "register",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(
        "inscripcion-estudiante",
        "jpg"
      ),
      link: "https://drive.google.com/file/d/1ih_wys0Q7avcx54Z7qBxJyq13aa6MpTy/view?usp=drive_link",
    }
  ]

  constructor() { }

  ngOnInit(): void {/** */}

}
