<div class="box">
  <div class="box__wrapper">
    <div class="side side__front">
      <img [src]="tutorialCover" alt="cover" class="image" />
    </div>

    <div class="side side__back">
      <a [href]="tutorialLink" target="_blank" rel="noopener" class="back__link">
        <em class="icon-i-play play-icon"></em>
        <div class="container__link-title">{{ tutorialName }}</div>
        <div class="container__link-app">{{ appName }}</div>
      </a>
    </div>
  </div>
</div>
