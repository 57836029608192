<div class="tutorials">
  <ng-container *ngFor="let category of categories">
    <div class="tutorials__section">
      <h2 class="tutorials__title">{{ category.label }}</h2>
      <div class="tutorials__content">
        <ng-container *ngFor="let item of tutorials">
          <tutorial-container *ngIf="item.category === category.value" [tutorialCover]="item.image"
            [coverCategory]="item.title" [tutorialName]="item.title" [tutorialLink]="item.link" appName="CELIC">
          </tutorial-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
