<div class="principalContainer">
  <div class="tableContainer" *ngIf="SCHOOL_NAME === 'CEDOC'">
    <table>
      <thead>
        <tr>
          <th>Escuela</th>
          <th>Correo soporte</th>
        </tr>
      </thead>
      <tbody>
        <tr><td>Cemil</td><td>admin.cemil@cedoc.edu.co</td></tr>
        <tr><td>Esace</td><td>admin.esace@cedoc.edu.co</td></tr>
        <tr><td>Esinf</td><td>admin.esinf@cedoc.edu.co</td></tr>
        <tr><td>Esart</td><td>admin.esart@cedoc.edu.co</td></tr>
        <tr><td>Esing</td><td>admin.esing@cedoc.edu.co</td></tr>
        <tr><td>Escom</td><td>admin.escom@cedoc.edu.co</td></tr>
        <tr><td>Esici</td><td>admin.esici@cedoc.edu.co</td></tr>
        <tr><td>ESAVE</td><td>admin.esave@cedoc.edu.co</td></tr>
        <tr><td>Esume</td><td>admin.esume@cedoc.edu.co</td></tr>
        <tr><td>Espom</td><td>admin.espom@cedoc.edu.co</td></tr>
        <tr><td>ESMAI</td><td>admin.esmai@cedoc.edu.co</td></tr>
        <tr><td>Esdae</td><td>admin.esdae@cedoc.edu.co</td></tr>
        <tr><td>Esfes</td><td>admin.esfes@cedoc.edu.co</td></tr>
        <tr><td>Eside</td><td>admin.eside@cedoc.edu.co</td></tr>
        <tr><td>Cemai</td><td>admin.cemai@cedoc.edu.co</td></tr>
        <tr><td>Emsub</td><td>admin.emsub@cedoc.edu.co</td></tr>
        <tr><td>espro</td><td>admin.espro@cedoc.edu.co</td></tr>
        <tr><td>BETRA</td><td>admin.betra@cedoc.edu.co</td></tr>
        <tr><td>cenae</td><td>admin.cenae@cedoc.edu.co</td></tr>
        <tr><td>diedu</td><td>admin.diedu@cedoc.edu.co</td></tr>
        <tr><td>Escab</td><td>admin.escab@cedoc.edu.co</td></tr>
        <tr><td>eslog</td><td>admin.eslog@cedoc.edu.co</td></tr>
        <tr><td>cides</td><td>admin.cides@cedoc.edu.co</td></tr>
        <tr><td>biter13</td><td>admin.biter13@cedoc.edu.co</td></tr>
      </tbody>
    </table>
  </div>
</div>
